/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const StyledLink = ({ text, href }) => {
  return (
    <Link
      to={href}
      sx={{
        pointer: "cursor",
        display: "block",
        maxWidth: "200px",
        textAlign: "center",
        boxSizing: "border-box",
        textDecoration: "none",
        border: "1px solid",
        borderColor: "primary",
        color: "primary",
        paddingX: 3,
        paddingY: "12px",
        width: "100%",
        textTransform: "uppercase",
        fontSize: 0,
        ":active, :hover, :focus": {
          backgroundColor: "primary",
          color: "white",
        },
      }}
    >
      {text}
    </Link>
  );
};

export default StyledLink;

StyledLink.propTypes = {
  text: PropTypes.string.isRequired,
};
